import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientOnly',
  setup(__props) {

const show = ref(false)
onMounted(() => {
    show.value = true
})

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : _createCommentVNode("", true)
}
}

})