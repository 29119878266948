import { QSpinner as _component_q_spinner } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-loader" }

import App from './App.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AppLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _createVNode(App)
    ]),
    fallback: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_spinner, {
          color: "primary",
          size: "xl"
        })
      ])
    ]),
    _: 1
  }))
}
}

})