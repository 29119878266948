import { QIcon as _component_q_icon } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "title"]


export default /*@__PURE__*/_defineComponent({
  __name: 'ExternalLink',
  props: {
    href: {},
    dark: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    title: _ctx.href,
    target: "_blank",
    rel: "noopener",
    class: _normalizeClass(["external-link", {
            dark: _ctx.dark
        }])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_q_icon, {
      name: "open_in_new",
      class: _normalizeClass({
                dark: _ctx.dark
            })
    }, null, 8, ["class"])
  ], 10, _hoisted_1))
}
}

})