import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "code-block" }
const _hoisted_2 = ["innerHTML"]

import hljs from 'highlight.js/lib/core'
import { onMounted, ref, watch } from 'vue'
import { escapeHtml } from '../utils/escapeHtml'
import { sleep } from '@/common/utils/sleep'
import { LanguageFn } from 'highlight.js'
import 'highlight.js/styles/monokai.css'


export default /*@__PURE__*/_defineComponent({
  __name: 'CodeBlock',
  props: {
    code: {
        type: String,
        required: true,
    },
    language: {
        type: String,
        default: 'css',
    },
    ignoreIllegals: {
        type: Boolean,
        default: true,
    },
    preWhiteSpace: {
        type: String,
        default: 'pre',
    },
},
  setup(__props) {

const props = __props

const languageMap = new Map<string, string>([
    ['js', 'javascript'],
    ['ts', 'typescript'],
    ['html', 'xml'],
])

const highlightedCode = ref<string>(escapeHtml(props.code))
watch(() => props, async() => {
    if (props.language === 'txt') {
        highlightedCode.value = escapeHtml(props.code)
    } else {
        if (!hljs.getLanguage(props.language)) {
            const fileName = languageMap.get(props.language) ?? props.language
            const { default: languageFn } = await import(`highlight.js/lib/languages/${fileName}`) as { default: LanguageFn }
            hljs.registerLanguage(props.language, languageFn)
        }

        highlightedCode.value = hljs.highlight(props.code, {
            language: props.language,
            ignoreIllegals: props.ignoreIllegals,
        }).value
    }
}, {
    immediate: true,
})

const hasClipboard = ref(false)
onMounted(() => {
    hasClipboard.value = 'clipboard' in navigator
})

const showCheckmark = ref(false)
async function copyToClipboard() {
    await navigator.clipboard.writeText(props.code)

    // Update icon temporarily
    showCheckmark.value = true
    await sleep(3000)
    showCheckmark.value = false
}

return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (hasClipboard.value)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          icon: showCheckmark.value ? 'check' : 'content_copy',
          outline: "",
          round: "",
          color: "white",
          title: "Copy code to clipboard",
          onClick: copyToClipboard
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("pre", {
      class: _normalizeClass(`hljs ${__props.language}`),
      style: _normalizeStyle(`white-space: ${props.preWhiteSpace};`)
    }, [
      _createElementVNode("code", { innerHTML: highlightedCode.value }, null, 8, _hoisted_2)
    ], 6)
  ]))
}
}

})